/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { ErrorHandler, Injectable } from '@angular/core';
import {
  CustomGlobalEvent,
  deserialize,
  GenericObject,
  getSupportedLocale,
  parseQueryString,
  requestErrorHandler,
  requestErrorHandlerWithStatus,
  WindowService,
} from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { get } from 'lodash-es';
import moment from 'moment';
import { forkJoin, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppConfig, CookiesService, Endpoint, EndpointV2, HttpService, I18NService } from '@ws1c/intelligence-common';
import { CoreAppState, UserPreferenceActions, UserPreferenceSelectors } from '@ws1c/intelligence-core/store';
import {
  AcceptEula,
  AcceptTrial,
  decodeAsString,
  IntelOrg,
  LOAD_STATE,
  Org,
  PendoEvent,
  PendoMetadata,
  PendoMetadataFields,
  PendoTrackEventType,
  PendoUpdateResponse,
  PendoVisitorMetadata,
  UI,
  UISettings,
  User,
  UserAccount,
  UserPreference,
} from '@ws1c/intelligence-models';

/**
 * UserPreferenceService
 * @export
 * @class UserPreferenceService
 */
@Injectable({
  providedIn: 'root',
})
export class UserPreferenceService {
  public userPreference: any;
  public authenticated: boolean = false;
  public moment: any = moment;
  public browserLocale: string;

  /**
   * Creates an instance of UserPreferenceService.
   * @param {HttpService} http
   * @param {Store<CoreAppState>} store
   * @param {CookiesService} cookieService
   * @param {I18NService} i18nService
   * @param {WindowService} windowService
   * @param {ErrorHandler} errorHandler
   * @memberof UserPreferenceService
   */
  constructor(
    private http: HttpService,
    private store: Store<CoreAppState>,
    private cookieService: CookiesService,
    private i18nService: I18NService,
    private windowService: WindowService,
    private errorHandler: ErrorHandler,
  ) {
    this.browserLocale = getSupportedLocale();
  }

  /**
   * init
   * Promise is used by app.module to determine when it's safe to start app
   * Gets the initialUrlQueryParams before loading app
   * Waits for user preferences to be safely in the app store before loading app
   * @returns {Promise<any>}
   * @memberof UserPreferenceService
   */
  public init(): Promise<any> {
    const initialUrlQueryParams = this.getUrlQueryParams();
    this.store.dispatch(UserPreferenceActions.setInitialUrlQueryParams({ initialUrlQueryParams }));
    return this.loadUserPreference();
  }

  /**
   * getUrlQueryParams
   * gets the queryParams that are before the fragment
   * @returns {any}
   * @memberof UserPreferenceService
   */
  public getUrlQueryParams() {
    const queryParamsString = this.windowService.location.search.substr(1);
    return parseQueryString(queryParamsString);
  }

  /**
   * loadUserPreference
   * @returns {Promise<any>}
   * @memberof UserPreferenceService
   */
  public loadUserPreference(): Promise<any> {
    return new Promise((resolve) => {
      this.store
        .select(UserPreferenceSelectors.getUserPreferenceLoadState)
        // resolve even if loadState is FAILURE so the app will start
        // the loadState will be observed by the auth guard for redirection to /access-denied
        .subscribe((loadState: LOAD_STATE) => {
          if ([LOAD_STATE.SUCCESS, LOAD_STATE.FAILURE].includes(loadState)) {
            resolve(true);
          }
        });
    });
  }

  /**
   * setLanguage
   * called from app.module (APP_INITIALIZER)
   * @returns {Promise<any>}
   * @memberof UserPreferenceService
   */
  public setLanguage(): Promise<any> {
    const momentLocale = this.browserLocale.toLowerCase();
    // Try to set locale with format xx-yy first
    this.moment.locale(momentLocale);
    // xx-yy is not available, then use only xx
    if (this.moment.locale() !== momentLocale) {
      this.moment.locale(momentLocale.split('-')[0]);
    }
    // returns a Promise
    return this.i18nService.setLanguage(this.browserLocale.replace('-', '_'));
  }

  /**
   * getUserPreference
   * @returns {Observable<UserPreference>}
   * @memberof UserPreferenceService
   */
  public getUserPreference(): Observable<UserPreference> {
    const uiInfo = this.http.get(Endpoint.UI_ROOT).pipe(
      map((response: any) => deserialize(UI, response.data)),
      catchError(requestErrorHandler),
    );
    const orgInfo = this.http.get(Endpoint.UI_ORG).pipe(
      map((response: any) => deserialize(Org, response.data)),
      catchError(requestErrorHandler),
    );
    const userAccountInfo = this.http.get(Endpoint.UI_USER_ACCOUNT).pipe(
      map((response: any) => deserialize(UserAccount, response.data)),
      catchError(requestErrorHandler),
    );
    const userInfo = this.http.get(Endpoint.UI_USER).pipe(
      map((response: any) => deserialize(User, response.data)),
      catchError(requestErrorHandler),
    );
    const userPreferenceInfo = this.http.get(Endpoint.USER_PREFERENCE).pipe(
      map((response: any) => deserialize(UISettings, response.data)),
      catchError(requestErrorHandler),
    );

    return forkJoin([uiInfo, orgInfo, userAccountInfo, userInfo, userPreferenceInfo]).pipe(
      map(([ui, org, userAccount, user, uiSettings]: [UI, Org, UserAccount, User, UISettings]) => {
        // API set ORG_NAME in cookies with short TTL so we need to read and cache it in local storage
        let externalOrgName = this.cookieService.getCookie(AppConfig.ORG_NAME);
        if (externalOrgName) {
          localStorage.setItem(AppConfig.ORG_NAME, externalOrgName);
        } else {
          // Fallback to local storage as the cookie is set from a redirect from the UEM console
          // and the cookie might have expired
          externalOrgName = localStorage.getItem(AppConfig.ORG_NAME) || '';
        }

        try {
          org.externalOrgName = decodeAsString(externalOrgName);
        } catch (error: unknown) {
          this.errorHandler.handleError(error);
        }

        // Combine response from user and userAccount API's
        userAccount = new UserAccount({
          ...userAccount,
          ...user,
        });
        const isCspLoggedInUser: boolean = org.cspLinkedOrg && !user.isUemIdentity;
        if (!isCspLoggedInUser) {
          org.externalOrgUrl = ui.sessionAttributes.ORIGINATION_URL;
        }

        org.displayName = isCspLoggedInUser ? org.orgName : org.externalOrgName;

        return new UserPreference({
          ui,
          org,
          userAccount,
          uiSettings,
        });
      }),
    );
  }

  /**
   * acceptEula
   * @param {AcceptEula} formData
   * @returns {Observable<boolean | any[]>}
   * @memberof UserPreferenceService
   */
  public acceptEula(formData: AcceptEula): Observable<boolean | any[]> {
    return this.http.post(EndpointV2.ACCEPT_EULA, formData).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * The endpoint is pointed at userPreference endpoint even though the put is for uiPreferences
   * Endpoint does not return anything
   * @param {any} uiPreferences
   * @returns {Observable<any>}
   * @memberof UserPreferenceService
   */
  public updateUiPreferences(uiPreferences: any) {
    return this.http.put(Endpoint.UI_ROOT, uiPreferences).pipe(catchError(requestErrorHandler));
  }

  /**
   * declineTrial
   * @returns {Observable<boolean>}
   * @memberof UserPreferenceService
   */
  public declineTrial(): Observable<boolean | any[]> {
    return this.http.post(Endpoint.DECLINE_TRIAL, {}).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Retrieves contact details for trial user
   * @returns {Observable<AcceptTrial>}
   * @memberof UserPreferenceService
   */
  public getTrialUserContactDetails(): Observable<AcceptTrial> {
    return this.http.get(Endpoint.TRIAL_CONTACT_DETAILS).pipe(
      map((response: any) => deserialize(AcceptTrial, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * startTrial
   * @param {AcceptTrial} formData
   * @returns {Observable<boolean>}
   * @memberof UserPreferenceService
   */
  public startTrial(formData: AcceptTrial): Observable<boolean | any[]> {
    return this.http.post(Endpoint.START_TRIAL, formData).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getUserScopes
   * @returns {Observable<string[]>}
   * @memberof UserPreferenceService
   */
  public getUserScopes(): Observable<string[]> {
    return this.http.get(Endpoint.SESSION_SCOPES).pipe(
      map((response: any) => response.data),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Initializes Pendo telemetry
   *
   * @param {UserPreference} userPreference - Holds user preference details
   * @param {string} pendoAccountId - Pendo account id
   * @param {string} pendoVisitorId - Pendo visitor id
   * @param {boolean} blacklistGuides - Flag to indicate if guides are black listed
   * @memberof UserPreferenceService
   */
  public initializePendo(userPreference: UserPreference, pendoAccountId: string, pendoVisitorId: string, blacklistGuides: boolean) {
    const pendo: typeof AppConfig.PENDO = AppConfig.PENDO;
    const { org, userAccount }: { org: Org; userAccount: UserAccount } = userPreference;
    const { orgId, displayName } = org;
    pendo.initialize({
      visitor: {
        id: pendoVisitorId,
        role: (userAccount?.roles ?? []).map((role: GenericObject) => role.name),
      },
      account: {
        id: pendoAccountId,
        is_paying: !org?.trialBanner?.showBanner,
        orgId,
        orgName: displayName,
        planLevel: org?.activeSkus ?? [],
        region: this.getRegion(),
        additional_skus: org?.additionalSkus ?? [],
      },
      events: {
        ready: () => {
          // This handler is invoked when pendo initialization is complete
          pendo.setGuidesDisabled(blacklistGuides);
          pendo.isInitialized = true;
        },
      },
    });
  }

  /**
   * Emits a Pendo tracking event
   *
   * @param {PendoEvent} event
   * @memberof UserPreferenceService
   */
  @CustomGlobalEvent.listen(PendoTrackEventType)
  public trackPendoEvent(event: PendoEvent) {
    const pendo: typeof AppConfig.PENDO = AppConfig.PENDO;
    if (pendo?.isInitialized) {
      pendo.track(event.category, event.data);
    }
  }

  /**
   * Updates UI preferences
   * @param {UISettings} uiSettings - Holds ui settings which needs to be updated
   * @returns {Observable<boolean>}
   * @memberof UserPreferenceService
   */
  public updateUISettings(uiSettings: UISettings): Observable<boolean> {
    return this.http.patch(Endpoint.USER_PREFERENCE, uiSettings).pipe(
      map(() => true),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Retrieve pendo metadata for a visitor id
   * @param {string} visitorId - visitor id
   * @returns {Observable<PendoMetadata>}
   * @memberof UserPreferenceService
   */
  public getPendoMetadata(visitorId: string): Observable<PendoMetadata> {
    return this.http.get(Endpoint.PENDO_VISITOR_GET_ALL_METADATA(visitorId)).pipe(
      map((response: any) => {
        return new PendoMetadata({
          [PendoMetadataFields.blacklist_guides]: response?.metadata?.pendo?.blacklistguides ?? false,
          [PendoMetadataFields.do_not_process]: response?.metadata?.pendo?.donotprocess ?? false,
        });
      }),
      catchError(requestErrorHandlerWithStatus),
    );
  }

  /**
   * Updates pendo metadata for visitors
   * @param {PendoVisitorMetadata[]} pendoVisitorsMetadata - pendo visitors metadata list
   * @returns {Observable<PendoUpdateResponse>}
   * @memberof UserPreferenceService
   */
  public updatePendoMetadata(pendoVisitorsMetadata: PendoVisitorMetadata[]): Observable<PendoUpdateResponse> {
    return this.http.post(Endpoint.PENDO_VISITOR_UPDATE_METADATA, pendoVisitorsMetadata).pipe(
      map((response: GenericObject) => deserialize(PendoUpdateResponse, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getUserAccount
   * @returns {Observable<UserAccount>}
   * @memberof UserPreferenceService
   */
  public getUserAccount(): Observable<UserAccount> {
    return this.http.get(Endpoint.UI_USER_ACCOUNT).pipe(
      map((response: GenericObject) => deserialize(UserAccount, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getUserInfo
   * @returns {Observable<User>}
   * @memberof UserPreferenceService
   */
  public getUserInfo(): Observable<User> {
    return this.http.get(Endpoint.UI_USER).pipe(
      map((response: GenericObject) => deserialize(User, response.data)),
      catchError(requestErrorHandler),
    );
  }

  /**
   * getUserOrgs
   * @param {string} email
   * @returns {Observable<IntelOrg[]>}
   * @memberof UserPreferenceService
   */
  public getUserOrgs(email: string): Observable<IntelOrg[]> {
    return this.http.get(Endpoint.USER_ORGS(email)).pipe(
      map((response) => response.data.map((orgJson: GenericObject) => deserialize(IntelOrg, orgJson))),
      catchError(requestErrorHandler),
    );
  }

  /**
   * Returns region slice from host
   * @returns {Observable<string>}
   * @memberof UserPreferenceService
   */
  private getRegion(): string {
    return get(this.windowService, 'location.hostname', '').split('.').shift();
  }
}
