/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { GoSidepanelDetails, GoSidepanelEventType } from '@ws1c/intelligence-models';

/**
 * GoSidepanelService
 * @exports
 * @class GoSidepanelService
 */
@Injectable({
  providedIn: 'root',
})
export class GoSidepanelService {
  public stream$ = new Subject<GoSidepanelEventType>();

  /**
   * show
   * @param {GoSidepanelDetails} details
   * @memberof GoSidepanelService
   */
  public show(details: GoSidepanelDetails) {
    this.stream$.next({
      show: true,
      details,
    });
  }

  /**
   * hide
   * @memberof GoSidepanelService
   */
  public hide() {
    this.stream$.next({
      show: false,
    });
  }
}
