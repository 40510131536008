/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { gql } from 'apollo-angular';

export const ACCOUNT_SEARCH = gql`
  query AccountSearch($pagedSearchRequestInput: PagedSearchRequestInput!) {
    accountDetailsV3(input: $pagedSearchRequestInput) {
      paged_response {
        offset
        page_size
        total_count
      }
      account_details {
        id
        org_id
        active
        display_name
        email
        first_name
        last_name
        created_at
        last_logon_time
        directory_user {
          directory_id
          directory_type
          directory_user_id
        }
        csp_migration_status
        csp_migration_email
        data_access_policies {
          id
          name
          description
          integration {
            name
          }
          filter_configuration_type
          org_id
          enabled
          created_at
          modified_at
          created_by
          modified_by
        }
        roles {
          id
          label
          name
          scopes {
            id
            name
          }
          created_at
          created_by
        }
      }
    }
  }
`;
